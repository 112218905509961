import React from "react";
import DatePicker from "react-datepicker";
// import MonthPicker from 'react-native-month-year-picker';

const DateIp = ({
  setValue,
  value = null,
  placeholder = "",
  formcontrolclass = "",
  ...props
}) => {
  return (
    <DatePicker
      dateFormat="dd/MM/yyyy"
      className={`form-control ${formcontrolclass}`}
      {...props}
      placeholderText={placeholder}
      selected={value}
      onChange={(date) => setValue(date)}
    />
  );
};

export const DateTimeIp = (props) => {
  return <DateIp {...props} dateFormat="MMMM d, yyyy h:mm aa" showTimeSelect />;
};

export const DateRange = ({ setValue, value = {} }) => {
  const { from, to } = value;
  return (
    <div className="form-row">
      <div className="col-6">
        <DatePicker
          className="form-control mr-4"
          selected={typeof from === "string" ? new Date(from) : from}
          onChange={(date) => setValue({ ...value, from: date })}
          selectsStart
          startDate={typeof from === "string" ? new Date(from) : from}
          endDate={typeof to === "string" ? new Date(to) : to}
          placeholderText="From Date"
          dateFormat="yyyy-MM-dd"
        />
      </div>
      <div className="col-6">
        <DatePicker
          className="form-control"
          selected={typeof to === "string" ? new Date(to) : to}
          onChange={(date) => setValue({ ...value, to: date })}
          selectsEnd
          startDate={typeof from === "string" ? new Date(from) : from}
          endDate={typeof to === "string" ? new Date(to) : to}
          minDate={typeof from === "string" ? new Date(from) : from}
          placeholderText="To Date"
          dateFormat="yyyy-MM-dd"
        />
      </div>
    </div>
  );
};

export const YearPicker = (props) => {
  return <DateIp {...props} showYearPicker dateFormat={"yyyy"} />;
};
export const MonthYearPicker = (props) => {
  return <DateIp {...props} showMonthYearPicker dateFormat={"yyyy-MM"} />;
};

export const TimePicker = (props) => {
  return (
    <DateIp
      {...props}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption="Time"
      dateFormat="h:mm aa"
    />
  );
};

export const TimeRange = ({ setValue, value = {} }) => {
  return (
    <div className="d-flex">
      <div>
        <DatePicker
          className="form-control"
          selected={value.from}
          onChange={(date) => setValue({ ...value, from: date })}
          selectsStart
          placeholderText="From"
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="From Time"
          dateFormat="h:mm aa"
        />
      </div>
      <div className="ml-2 mr-2"></div>
      <div>
        <DatePicker
          className="form-control"
          selected={value.to}
          onChange={(date) => setValue({ ...value, to: date })}
          selectsEnd
          startDate={value.from}
          endDate={value.to}
          minDate={value.from}
          placeholderText="To Time"
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
        />
      </div>
    </div>
  );
};

export default DateIp;
