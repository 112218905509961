import React, { useState } from "react";
import Switchbutton from "./Switchbutton";
import { AttendanceInfo } from "../sales-manager/attendance/AttendanceInfo";
import { useMasterValues } from "../common/MasterData";

export const AttendanceSwitch = () => {
  const [projectsFetching, { cur_attendance }] = useMasterValues();

  const initPop = !cur_attendance?.attendance ?? false;
  const [isChecked, setIsChecked] = useState(
    cur_attendance?.attendance ?? false
  );
  console.log(initPop);
  const [openModal, setModal] = useState(true);
  const [handleSwitch, setHandleSwitch] = useState(false);

  const handleSwitchToggle = (isChecked) => {
    setHandleSwitch(true);
    setIsChecked(true);
    setModal(true);
  };

  const closeModal = (checked) => {
    setIsChecked(checked);
    setModal(false);
    setHandleSwitch(false);
  };

  if (handleSwitch) {
    return (
      <>
        <Switchbutton setValue={handleSwitchToggle} checked={isChecked} />
        <AttendanceInfo
          title={"Attendance Details"}
          openModal={openModal}
          onClose={closeModal}
        />
      </>
    );
  }

  return (
    <>
      <Switchbutton setValue={handleSwitchToggle} checked={isChecked} />
      {initPop && (
        <AttendanceInfo
          title={"Attendance Details"}
          initpop={true}
          openModal={openModal}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default AttendanceSwitch;
