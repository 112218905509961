import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";

import { masterUrls } from "../../apiService/urls";
import useFetchData from "../../hooks/http/useFetchData";
import useUser from "../../hooks/user/useUser";
import { loadLocalState, saveLocalState } from "../../utils/localstorage";

const MasterContext = createContext([false, {}, false]);

export const useMasterValues = () => {
  return useContext(MasterContext).value;
};

export const useMasterActions = () => {
  return useContext(MasterContext).actions;
};

const MasterData = ({ children }) => {
  const { token, role } = useUser()[0];
  const userRole = role?.id ?? "";
  const [state, { setUrl, reload }] = useFetchData();

  useEffect(() => {
    if (token) {
      setUrl(`${masterUrls.activeMaster}?date=${new Date().toISOString()}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, userRole]);

  const reloadMaster = useCallback(() => {
    reload(masterUrls.activeMaster);
  }, [reload]);

  const data = state?.data ?? {};
  useEffect(() => {
    const logged_info = loadLocalState();
    if (state.fetching) {
      delete logged_info.current_project;
      delete logged_info.immediate_visit;
      delete logged_info.attendance;
    }

    if (state?.data?.cur_attendance) {
      logged_info.attendance = state?.data?.cur_attendance?.attendance;

      if (state?.data?.cur_attendance?.current_project) {
        logged_info.current_project =
          state?.data?.cur_attendance?.current_project;
        logged_info.immediate_visit = true;
      } else {
        logged_info.current_project = "";
        logged_info.immediate_visit = false;
      }
      saveLocalState(logged_info);
    }
  }, [state]);
  return (
    <MasterContext.Provider
      value={{
        value: [state.fetching, data, state.error],
        actions: { reload: reloadMaster },
      }}
    >
      {children}
    </MasterContext.Provider>
  );
};

export default MasterData;
